import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorManagerService {
  public createHttpRequestErrorMessage(error: HttpErrorResponse): string {
    if (error.error?.message) {
      return error.error.message;
    } else if (error.error?.error) {
      return error.error.error;
    } else if (error.status === 500) {
      return 'An error occurred in the server';
    } else {
      return error.message;
    }
  }

  public extractAccessError(error: HttpErrorResponse): string {
    if (error.error?.error?.description) {
      return error.error.error.description;
    }
    return 'An error occurred in the server';
  }
}
