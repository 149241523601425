<!--
 Copyright Banque de France. All Rights Reserved.
 This file is the property of Banque de France.
 It cannot be copied and/or distributed without the express
 permission of Banque de France.
-->
<div id="header-bar-container">
  <div id="header-bar-main" class="background-header" [style]="headerBackgroundImageStyle">
    <div class="header-content">
      <div class="logo-container">
        <img [src]="themeService.setLogo()" alt="logo" />
      </div>

      <ng-container *ngIf="displayHeader">
        <div class="project-name">
          <h1 class="header-title">{{ appTitle }}</h1>
        </div>

        <div *ngIf="displayEnv" class="current-env">
          <h1 class="env-tag" [style]="envLabelColor">{{ currentEnv }}</h1>
        </div>

        <div *ngIf="isUserLoggedIn" class="header-component header-2">
          <div class="date-info">
            <div class="date-info-row">
              <div *ngIf="now" class="date-info-title">Calendar date</div>
              <div class="date-info-content">{{ now | date : 'MM-dd-yyyy HH:mm:ss' }}</div>
            </div>
            <div *ngIf="businessDate" class="date-info-row">
              <div class="date-info-title">Business date</div>
              <div class="date-info-content">{{ businessDate | date : 'MM-dd-yyyy' }}</div>
            </div>
            <div *ngIf="businessWindows.length" class="date-info-row" [class.disabled]="businessWindowsDisabled">
              <div class="date-info-title">Business window</div>
              <div class="date-info-content" *ngIf="businessWindows.length">{{ businessWindows | currentWindow : 5 | async }}</div>
            </div>
          </div>
        </div>

        <div class="header-component profile-container">
          <div class="user">
            <div class="name">{{ user?.username }} {{ context }}</div>
            <div class="role-container">
              <div class="role" [class.role-show-all]="userType.length <= 6">
                <div class="role-placeholder">roles</div>
                <ng-container *ngFor="let type of userType; let i = index">
                  <div *ngIf="i < 2 || userType.length === 3">{{ type }}</div>
                </ng-container>

                <div *ngIf="userType.length > 3" class="more-roles">
                  <span>+{{ userType.length - 2 }}</span>
                  <ul class="tooltip">
                    <li *ngFor="let type of userType">{{ type }}</li>
                  </ul>
                </div>
              </div>
              <img
                class="logout"
                src="assets/img/svg/user_logout.svg"
                alt="logout"
                (click)="logoutUser()"
                [style]="headerIconStyle" />
            </div>
            <div class="version" *ngIf="displayVersion?.displayOnHeader">
              {{ displayVersion.version }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
