// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '@app/services/common/loader/loader.service';
import { SubscriptionService } from '@app/services/common/subscription.service';
import { environment } from '@env/environment';
import { ThemeService } from '@app/services/theme/theme.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  public show = false;
  private _loaderSubscription: Subscription;
  private _theme: string = environment.theme;

  public constructor(
    private _loaderService: LoaderService,
    private _themeService: ThemeService,
    private _subscriptionService: SubscriptionService
  ) {}

  public ngOnInit(): void {
    this._themeService.setTheme(this._theme);

    this._loaderSubscription = this._loaderService.loaderState.subscribe((state: { show: boolean }) => {
      this.show = state.show;
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionService.unsubscribe(this._loaderSubscription);
  }
}
