import { Component } from '@angular/core';
import { FieldArrayType } from "@ngx-formly/core";

@Component({
  selector: 'formly-repeat-section',
  templateUrl: './repeat-section-type.component.html',
  styleUrls: ['./repeat-section-type.component.scss']
})
export class RepeatSectionTypeComponent extends FieldArrayType {
}
