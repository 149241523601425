// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export class SubscriptionService {
  public unsubscribe(...subscriptions: Subscription[]): void {
    for (const subscription of subscriptions) {
      if (subscription) {
        subscription.unsubscribe();
      }
    }
  }
}
