<div class="dark-blue-margin"></div>
<div class="blue-margin"></div>
<div id="login-container">
  <div class="login-title">
    <span>{{ themeService.setLoginTitle() }}</span>
  </div>
  <div class="login-form-container">
    <form class="login-form" [formGroup]="loginFormGroup">
      <div *ngIf="errorMessage" class="error-message" [innerHTML]="errorMessage"></div>

      <ng-container *ngIf="authenticationFlow === 'basic'">
        <div id="registration-number-form-field" class="form-field">
          <div class="field-title">Email *</div>
          <input type="text" id="login-name" placeholder="Email" formControlName="email" required />
        </div>

        <div id="password-form-field" class="form-field">
          <div class="field-title">Password *</div>
          <input type="password" id="login-password" placeholder="Password" formControlName="password" required />
        </div>

        <div class="submit-button-container">
          <button [disabled]="loginFormGroup.invalid" mat-stroked-button (click)="basicLogin()">
            {{ loginFormGroup.pending ? 'Loading' : 'Login' }}
          </button>
        </div>
      </ng-container>
    </form>
  </div>
</div>
