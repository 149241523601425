import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { EnvironmentModel } from '@env/environment.model';
import { firstValueFrom, of } from 'rxjs';

@Injectable()
export class AppConfig {
  private config: Object = null;
  private env: Object = null;

  constructor(private http: HttpClient) {}

  /**
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: any) {
    return this.config[key];
  }

  /**
   * Use to get the data found in the first file (env file)
   */
  public getEnv(key: any) {
    return this.env[key];
  }

  /**
   * This method:
   *   Loads "config.json" to get the current working environment (e.g.: 'ci.dev', 'marianne.expeoc2021.dev')
   */
  public load() {
    if (this.config) {
      return firstValueFrom(of(true));
    }

    return new Promise<boolean>((resolve, reject): void => {
      this.http.get('assets/config/config.json').subscribe({
        next: (res: EnvironmentModel): void => {
          this.config = JSON.parse(JSON.stringify(res));
          if (environment['newConfigSystem']) {
            this.overrideEnvironmentFile();
          }
          resolve(true);
        },
        error: (): void => {
          reject(false);
        }
      });
    });
  }

  private overrideEnvironmentFile() {
    Object.keys(this.config).forEach((key) => {
      environment[key] = this.config[key];
    });
  }
}
