import { BusinessWindow, BusinessWindowScope } from "./business-date.model";
import { DateTime } from "luxon";
import { User } from "@models/access/user.model";

export class BusinessWindowHelper {
  public static readonly TIMEZONE: string = "Europe/Paris";

  public static computeCurrentWindow(businessWindows: BusinessWindow[]): BusinessWindow {
    if (businessWindows.length === 0) {
      throw new Error("business windows is empty, current window cannot be computed")
    }
    const currentTime: DateTime = BusinessWindowHelper.getCurrentTime();

    let previousWindow: BusinessWindow = businessWindows[businessWindows.length - 1];
    let currentWindow: BusinessWindow;
    for (currentWindow of businessWindows) {
      const bizTime: DateTime = BusinessWindowHelper.getDateTime(currentWindow);
      if (currentTime < bizTime) {
        break;
      }
      previousWindow = currentWindow;
    }
    return previousWindow;
  }

  public static computeNextWindow(businessWindows: BusinessWindow[]): BusinessWindow {
    if (businessWindows.length === 0) {
      throw new Error("business windows is empty, current window cannot be computed")
    }
    const currentTime: DateTime = BusinessWindowHelper.getCurrentTime();

    let nextWindow: BusinessWindow = businessWindows[0];
    for (let currentWindow of businessWindows) {
      const bizTime: DateTime = BusinessWindowHelper.getDateTime(currentWindow);
      
      if (currentTime < bizTime) {
        nextWindow = currentWindow;
        break;
      }
    }
    return nextWindow;
  }

  public static calculateClosingBusinessWindowFor(externalEntity: boolean): BusinessWindowScope {
    if (externalEntity) {
      return BusinessWindowScope.END_OF_DAY;
    }
    return BusinessWindowScope.CLOSED;
  }

  public static calculateOpeningBusinessWindowFor(externalEntity: boolean): BusinessWindowScope {
    if (externalEntity) {
      return BusinessWindowScope.OPEN_FOR_ALL;
    }
    return BusinessWindowScope.START_OF_DAY;
  }

  public static getBusinessWindow(businessWindows: BusinessWindow[], name: BusinessWindowScope): BusinessWindow {
    return businessWindows.find((businessWindow: BusinessWindow): boolean => businessWindow.name === name);
  }

  public static isEndOfDay(businessWindows: BusinessWindow[]): boolean {
    const now: DateTime = BusinessWindowHelper.getCurrentTime();

    const endOfDayBusinessWindow: BusinessWindow = BusinessWindowHelper.getBusinessWindow(businessWindows, BusinessWindowScope.END_OF_DAY);
    const closingBusinessWindow: BusinessWindow = BusinessWindowHelper.getBusinessWindow(businessWindows, BusinessWindowScope.CLOSED);

    return now >= BusinessWindowHelper.getDateTime(endOfDayBusinessWindow) &&
      now < BusinessWindowHelper.getDateTime(closingBusinessWindow);
  }

  public static platformIsAccessible(businessWindows: BusinessWindow[], currentUser: User): boolean {
    if (currentUser.isOperator) {
      return true;
    }
    const now: DateTime = BusinessWindowHelper.getCurrentTime();

    const openingScope: BusinessWindowScope = BusinessWindowHelper.calculateOpeningBusinessWindowFor(currentUser.isExternalEntity);
    const openingBusinessWindow: BusinessWindow = BusinessWindowHelper.getBusinessWindow(businessWindows, openingScope);

    const closingScope: BusinessWindowScope = BusinessWindowHelper.calculateClosingBusinessWindowFor(currentUser.isExternalEntity);
    const closingBusinessWindow: BusinessWindow = BusinessWindowHelper.getBusinessWindow(businessWindows, closingScope);

    return now > BusinessWindowHelper.getDateTime(openingBusinessWindow) &&
      now < BusinessWindowHelper.getDateTime(closingBusinessWindow);
  }

  public static getUpcomingClosingDate(businessWindows: BusinessWindow[], currentUser: User) {
    const closingWindowScope: BusinessWindowScope = this.calculateClosingBusinessWindowFor(currentUser.isExternalEntity);
    const closing: BusinessWindow = this.getBusinessWindow(businessWindows, closingWindowScope);

    return BusinessWindowHelper.getDateTime(closing);
  }

  public static remainingTimeTo(businessWindows: BusinessWindow[], window: BusinessWindowScope): number {
    const now: DateTime = BusinessWindowHelper.getCurrentTime();
    const nextWindow: BusinessWindow = this.getBusinessWindow(businessWindows, window);
    let upcomingDate: DateTime = BusinessWindowHelper.getDateTime(nextWindow);
    if (upcomingDate < now) {
      upcomingDate = upcomingDate.plus({ day: 1 });
    }
    return upcomingDate.diff(now, 'milliseconds').milliseconds;
  }

  public static getCurrentTime(): DateTime {
    return DateTime.now().setZone(this.TIMEZONE);
  }

  public static getDateTime(businessWindow: BusinessWindow): DateTime {
    return DateTime.fromFormat(businessWindow.startTime, "HH:mm", { zone: this.TIMEZONE });
  }
}
