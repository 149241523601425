import { Authentication } from '../authentication.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Tools } from '../../common/tools';
import { Routing } from '@core/enum/routing.enum';
import { AuthResponseDto } from '@models/access/auth-response.dto';
import { map, switchMap } from 'rxjs/operators';
import { EntityDto } from '@models/entities/entity.dto';
import { User } from '@models/access/user.model';
import { Role } from '@models/roles/role';
import { UserProfile } from '@app/services/authentication/implementations/keycloak.implementation';

/**
 *
 * @Deprecated
 */
export class AccessImplementation implements Authentication {
  public onAuthChange: BehaviorSubject<User>;
  private readonly _baseUrl: string;
  private readonly _baseOctopusUrl: string;

  constructor(private httpClient: HttpClient) {
    this._baseUrl = environment.apiUrl + 'api';
    this._baseOctopusUrl = environment.octopusUrl + 'api/octopus';
    this.setLoggedInUser();
    window.addEventListener('storage', (event) => {
      if (event.newValue !== null) {
        this.onAuthChange.next(Tools.getSessionUser());
        location.reload();
      }
    });
  }

  public login(email: string, password: string): Observable<boolean> {
    const loginUrl: string = `${this._baseUrl}${Routing.Login}`;
    const entitiesUrl: string = `${this._baseOctopusUrl}${Routing.Entities}`;

    return this.httpClient.post<AuthResponseDto>(loginUrl, { email, password }).pipe(
      switchMap((result: AuthResponseDto) =>
        this.httpClient
          .get<EntityDto>(`${entitiesUrl}/${result['user'].entity}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + result.token
            }
          })
          .pipe(
            map((entityResponse: EntityDto) => {
              const user = new User(
                result.user.id,
                result.user.username,
                result.user.givenName,
                result.user.familyName,
                result.user.email,
                result.user.company,
                result.user.roles || [],
                result.user.permissions || [],
                result.user.entity,
                result.user.blockAccessOrg,
                entityResponse.roles_table as Role[],
                result.user.externalEntityID,
                result.user.networkID,
              );
              // TODO: manage case where entity.roles_table is empty and raise error
              Tools.setupSessionMaterial(user, result.token);
              this.onAuthChange.next(user);
              return true;
            })
          )
      )
    );
  }

  public loadUserProfile(info?: UserProfile): Observable<User> {
    return undefined;
  }

  public isLoggedIn(): Observable<boolean> {
    return of(Tools.isSessionUp() && !Tools.isSessionExpired());
  }

  private setLoggedInUser(): void {
    if (!Tools.isSessionUp() && !Tools.isSessionExpired()) {
      // FORCE LOGOUT
      this.logout();
      return;
    }
    try {
      this.onAuthChange.next(Tools.getSessionUser());
    } catch (err) {
      this.logout();
      throw err;
    }
  }

  // clear token and remove user from local storage to log user out
  public logout(): Observable<boolean> {
    Tools.cleanSessionMaterial();
    this.onAuthChange.next(null);
    return of(true);
  }
}
