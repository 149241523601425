/*
 * Copyright IBM Corp. All Rights Reserved.
 * Unauthorized copying/modification of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, AsyncSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { User } from '@models/access/user.model';
import { Authentication } from '@app/services/authentication/authentication.interface';
import { AccessImplementation } from '@app/services/authentication/implementations/access.implementation';
import {
  KeycloakImplementation,
  UserProfile
} from '@app/services/authentication/implementations/keycloak.implementation';
import { tap } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';
import { EntitiesService } from '@app/services/entities/entities.service';
import { IdentityProviders } from '@env/environment.model';
import { Tools } from "@app/services/common/tools";

@Injectable()
export class AuthenticationService {
  public onAuthChange: BehaviorSubject<User> = new BehaviorSubject(null);
  private _implementation: Authentication;

  public constructor(_httpClient: HttpClient, _keycloakService: KeycloakService, _entitiesService: EntitiesService) {
    if (environment.identityProvider?.name === IdentityProviders.KEYCLOAK) {
      this._implementation = new KeycloakImplementation(_keycloakService, _entitiesService);
    } else {
      this._implementation = new AccessImplementation(_httpClient);
    }
    this.isLoggedIn().subscribe({
      next: (isLoggedIn: boolean): void => {
        if (isLoggedIn) {
          this.loadUserProfile().subscribe();
        }
      }
    });
  }

  public login(email: string, password: string): Observable<boolean> {
    return this._implementation.login(email, password);
  }

  public loadUserProfile(info?: UserProfile): Observable<User> {
    return this._implementation.loadUserProfile(info).pipe(tap((user: User): void => this.onAuthChange.next(user)));
  }

  public isLoggedIn(): Observable<boolean> {
    return this._implementation.isLoggedIn();
  }

  // clear token and remove user from local storage to log user out
  public logout(): Observable<boolean> {
    Tools.cleanSessionMaterial();
    return this._implementation.logout();
  }
}
