import { AfterViewInit, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-date-type',
  templateUrl: './date-type.component.html',
  styleUrls: ['./date-type.component.scss']
})
export class FormlyFieldDateComponent extends FieldType implements AfterViewInit {
  public datePipe: DatePipe = new DatePipe('FR-fr');

  public readonly DEFAULT_FORMAT: string = 'yyyy-MM-dd';

  ngAfterViewInit(): void {
    this.formControl.valueChanges.subscribe((value: string) => {
      const format: string = this.props.format ?? this.DEFAULT_FORMAT;
      const formattedValue: string = this.datePipe.transform(value, format, 'Europe/Paris');
      if (value == formattedValue) {
        return;
      }
      this.formControl.setValue(formattedValue, { emitEvent: true });
    });
  }
}
