// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
import { environment } from '@env/environment';
import { ThemeService } from './services/theme/theme.service';
import { MenuMode } from './services/theme/theme';
import { Tools } from '@app/services/common/tools';
import { EntitiesService } from './services/entities/entities.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]
})
export class AppComponent implements OnInit {
  private loggedIn = false;
  public theme: string = environment.theme;
  public favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  public logoTitle: HTMLLinkElement = document.querySelector('#logoTitle');
  public title: string = environment.logoTitle;
  public fontsLoaded = false;

  constructor(
    private _authenticationService: AuthenticationService,
    private _entitiesService: EntitiesService,
    private _router: Router,
    private _location: Location,
    private _themeService: ThemeService
  ) {
    this.fillCacheEntities();
  }

  public async ngOnInit(): Promise<void> {
    this.logoTitle.innerHTML = this.title;
    this.favIcon.href = this._themeService.setFavIcon(this.theme);

    this._authenticationService.onAuthChange.subscribe((user) => {
      this.loggedIn = !!user;
    });

    const loc = this._location.path();
    if (this.loggedIn && (loc === '/login' || loc === '')) {
      this._router.navigate(['./app']);
    }

    this.fontsLoaded = await Tools.waitFontsLoad();
  }

  private async fillCacheEntities() {
    await firstValueFrom(this._entitiesService.getEntities());
  }
}
