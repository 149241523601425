// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { AppVersion, ThemeService } from '@app/services/theme/theme.service';
import { ParametersService } from '@app/services/parameters/parameters.service';
import { Tools } from '@app/services/common/tools';
import { User } from "@models/access/user.model";
import { BusinessWindow } from "@models/business-date/business-date.model";
import { EnvironmentColors } from "@models/environments/environments";
import { Role, RolesDisplayWalletBased } from "@models/roles/role";
import { Subscription } from 'rxjs';
import { BusinessWindowHelper } from '@models/business-date/business-window.helper';
import { AppConfig } from '@app/app.config';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit {
  public userType: string[];
  public blockAccessOrg: string;
  public context: string;
  public isUserLoggedIn: boolean;
  private _theme: string = environment.theme;
  public settlementEngineType: string = environment.settlementEngineType;
  public appTitle: string = environment.appTitle;
  public cashName: string = environment.CashNaming;
  public currentEnv: string;
  public businessDate: Date;
  public envLabelColor: string;
  public now: Date = new Date();
  public sessionsExpired: boolean;
  public headerBackgroundImageStyle: string;
  public headerIconStyle = '';
  public businessWindows: BusinessWindow[] = [];
  public displayVersion?: AppVersion;
  public displayHeader: boolean = false;
  public displayEnv: boolean = false;
  public user?: User;
  public isBDFEnv: boolean = false;

  public businessWindowsDisabled: boolean = false;

  public constructor(
    private _authenticationService: AuthenticationService,
    public themeService: ThemeService,
    private _router: Router,
    private parametersService: ParametersService,
    private configService: AppConfig,
  ) {
    setInterval(() => {
      this.now = new Date();
    }, 1);
    this.businessWindowsDisabled = this.configService.getConfig('disableBusinessWindows');
  }

  public ngOnInit(): void {
    this.sessionsExpired = Tools.isSessionExpired();
    this.themeService.setTheme(this._theme);
    this.displayVersion = this.themeService.setVersion(this._theme);
    if (this.themeService.getHeaderBackgroundImage()) {
      this.headerBackgroundImageStyle = `background-image: url(${this.themeService.getHeaderBackgroundImage()})`;
    } else {
      this.headerBackgroundImageStyle = `background-color: ${this.themeService.getHeaderBackgroundColor() ?? 'white'};`;
    }
    if (this.themeService.getHeaderInvertIconColor()) {
      this.headerIconStyle = `filter: invert(100%);-webkit-filter: invert(100%);`;
    }
    this.currentEnv = this.getCurrentEnvironmentFromConfig();
    if (this.displayEnv) {
      this.envLabelColor = this.isBDFEnv ? `background-color: ${EnvironmentColors[this.currentEnv] || EnvironmentColors['DEFAULT']};` : `background-color: ${EnvironmentColors['DEFAULT']};`;
    }
    this.listenToAuthChange();
  }

  public listenToAuthChange(): void {
    this._authenticationService.onAuthChange.subscribe((user: User): void => {
      this._authenticationService.isLoggedIn().subscribe((value: boolean): void => {
        this.isUserLoggedIn = value;
      });
      this.sessionsExpired = Tools.isSessionExpired();
      this.user = user;
      if (user == null || this.sessionsExpired) {
        this.context = '';
        this.userType = [];
        return;
      }

      if (this.settlementEngineType === 'TOKEN_BASE') {
        this.userType = [];
        for (let role of (user.appRoles as Role[])) {
          const displayRole: string = RolesDisplayWalletBased[role];
          this.userType.push(displayRole);
        }
      } else {
        for (let role of user.appRoles) {
          const displayRole: string = role.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
          this.userType.push(displayRole);
        }
      }
      this.context = user.getContext();
      this.getBusinessDate();
      this.getBusinessWindows();
      this.setBusinessDateRefresh();
    });
  }

  public logoutUser(): void {
    this._authenticationService.logout().subscribe(() => {
      this._router.navigate(['./login']);
    });
  }

  private getCurrentEnvironmentFromConfig(): string {
    if (environment.sentry.environment) {
      this.isBDFEnv = ((environment.sentry.environment).split('.')[1] === 'bdf');
    }
    
    let currentEnvironment = '';

    if (environment.environment !== undefined && environment.environment !== '' && environment.environment !== null) {
      this.displayEnv = true;
      currentEnvironment = environment.environment.toUpperCase();
    }

    return currentEnvironment;
  }

  private getBusinessDate() {
    this.parametersService.getBusinessDate().subscribe((businessDate: string): void => {
      this.businessDate = new Date(businessDate);
    });
  }

  private getBusinessWindows(): void {
    this.parametersService.getBusinessWindows().subscribe({
      next: (businessWindows: BusinessWindow[]): void => {
        this.businessWindows = businessWindows;
        this._computeHeaderDisplay();
      }
    });
  }

  private _computeHeaderDisplay() {
    if (!this.businessWindows.length || !this.user) {
      return;
    }

    this.displayHeader = this.businessWindowsDisabled || BusinessWindowHelper.platformIsAccessible(this.businessWindows, this.user);
  }

  private setBusinessDateRefresh() {
    // Refresh BD every minute
    setInterval(() => {
      this.getBusinessDate();
    }, 60000);
  }
}
