// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards';
import { LoginComponent } from './components/login/login.component';
import { PlatformClosedComponent } from "@app/components/platform-closed/platform-closed.component";
import { BusinessWindowGuard } from "@app/guards/business-window.guard";

const appRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'platform-closed',
    component: PlatformClosedComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'app',
    loadChildren: () => import('./components/logged-in/logged-in.module').then((m) => m.LoggedInModule),
    canActivate: [AuthGuard, BusinessWindowGuard],
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
