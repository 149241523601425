// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { AppVersion, ThemeService } from '@app/services/theme/theme.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  private _theme: string = environment.theme;
  public displayVersion?: AppVersion;
  public displayFooter: boolean;

  constructor(public themeService: ThemeService) {}

  public ngOnInit() {
    this.themeService.setTheme(this._theme);
    this.displayVersion = this.themeService.setVersion(this._theme);
    this.displayFooter = this.themeService.getMenuMode() === 'horizontal';
  }
}
