<div class="formly-container box">
  <div class="formly-title">
    <span>{{props.label}}</span>
    <button *ngIf="!props.disabled" class="btn btn-primary" type="button" (click)="add()"><em class="material-icons">add</em></button>
  </div>

  <div class="formly-body">
    <div *ngFor="let field of field.fieldGroup; let i = index" class="align-items-baseline row">
      <formly-field [ngClass]="field.fieldGroupClassName" [field]="field"></formly-field>
      <button *ngIf="!props.disabled && !field.fieldGroup[0].props.disabled && i != 0" class="btn btn-danger" type="button" (click)="remove(i)"><em class="material-icons">close</em></button>
    </div>
  </div>
</div>
