// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

export const EnvironmentColors: { [key: string]: string } = {
    DEV: '#FF1000', // red
    SANDBOX: '#D2691E', // brown
    UTEST: '#FFD700', // yellow
    QA: '#FFA500', // orange
    DEMO: '#137EE3', // light blue
    PREPROD: '#6495ED', // cornflower blue
    PROD: '#003b7d', // dark blue
    SWIFT: '#66AA00', // green
    BNP: '#66AA00', // green
    OENB: '#66AA00', // green
    HOM: '#FFA500', // orange,
    DEFAULT: '#135eb2' // blue
};
