// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoaderService {
  private loaderSubject = new Subject<any>();
  public loaderState = this.loaderSubject.asObservable();

  public show() {
    this.loaderSubject.next({ show: true } as any);
  }

  public hide() {
    this.loaderSubject.next({ show: false } as any);
  }
}
