import { Component, OnDestroy } from '@angular/core';
import { BusinessWindow } from "@models/business-date/business-date.model";
import { User } from "@models/access/user.model";
import { ParametersService } from "@app/services/parameters/parameters.service";
import { BusinessWindowHelper } from "@models/business-date/business-window.helper";
import { AuthenticationService } from "@app/services/authentication/authentication.service";
import { combineLatest, filter } from "rxjs";
import { isNonNull } from "@app/guards/business-window.guard";
import { Timer } from "@app/components/logged-in/logged-in.component";
import { AppConfig } from '@app/app.config';

@Component({
  standalone: true,
  selector: 'app-platform-closed',
  templateUrl: './platform-closed.component.html',
  styleUrls: ['./platform-closed.component.scss']
})
export class PlatformClosedComponent {
  public counterBeforeLogout: number = 20;

  constructor(
    private _authService: AuthenticationService,
    private _parameterService: ParametersService,
    private _configService: AppConfig
  ) {
    const disableBusinessWindows: boolean = this._configService.getConfig("disableBusinessWindows");
    if (disableBusinessWindows) {
      this.redirectToDashboard();
      return
    }

    combineLatest([
      this._authService.onAuthChange,
      this._parameterService.getBusinessWindows()
    ]).pipe(filter(isNonNull)).subscribe({
      next: ([user, businessWindows]: [User, BusinessWindow[]]): void => {
        if (BusinessWindowHelper.platformIsAccessible(businessWindows, user)) {
          this.redirectToDashboard();
        } else {
          this.planLoginRedirection();
        }
      }
    })
  }

  public redirectToDashboard() {
    window.location.href = "/app";
  }

  public planLoginRedirection(): void {
    const interval: Timer = setInterval(() => {
      this.counterBeforeLogout -= 1;

      if (this.counterBeforeLogout == 0) {
        clearInterval(interval);
        this._authService.logout();
      }
    }, 1000);
  }
}
