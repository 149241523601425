// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

export interface Theme {
  name: string;
  properties: { [key: string]: string };
  logo?: string;
  headerBackgroundImage?: string;
  headerBackgroundColor?: string;
  headerInvertIconColor?: boolean;
  version?: string;
  loginTitle?: string;
  headerTitle?: string;
  footerLink?: string;
  seeClient?: boolean;
  seeCounterpart?: boolean;
  menuMode: MenuMode;
}

export type MenuMode = 'vertical' | 'horizontal';

const bdf: Theme = {
  name: 'bdf',
  logo: '../../../assets/img/logo_BDF_bleu_RVB.png',
  headerBackgroundImage: '../../../assets/img/bg-header_BDF_EN.png',
  headerInvertIconColor: false,
  version: '',
  loginTitle: '',
  headerTitle: 'DL3S',
  footerLink: 'Banque de France',
  seeClient: false,
  seeCounterpart: true,
  menuMode: 'vertical',
  properties: {
    '--color-theme-primary': '#135eb2', // $color-blue
    '--color-theme-primary-loader': '#137EE3', // $color-light-blue
    '--color-theme-primary-dark': '#003b7d', // $color-dark-blue
    '--color-theme-primary-very-dark': '#002A54', // $color-veary-dark-blue
    '--header-title-color': '#003b7d',
    '--header-text-color': '#003b7d',
    '--header-logo-height': '6rem',
    '--header-background-size': 'contain'
  }
};

const ibm: Theme = {
  name: 'ibm',
  logo: '../../../assets/img/marianne-black.png',
  headerBackgroundImage: '../../../assets/img/bg-header_BDF_EN.png',
  headerInvertIconColor: false,
  version: 'MARIANNE v2',
  loginTitle: 'Distributed engine for Securities Settlement',
  headerTitle: 'Distributed Settlement Engine',
  footerLink: 'IBM',
  seeClient: false,
  seeCounterpart: true,
  menuMode: 'horizontal',
  properties: {
    '--color-theme-primary': '#636363', // $color-grey
    '--color-theme-primary-loader': '#222222', // $color-black
    '--color-theme-primary-dark': '#222222', // $color-light-black
    '--color-theme-primary-very-dark': '#222222', // $color-light-black
    '--header-title-color': '#000000',
    '--header-text-color': '#000000',
    '--header-logo-height': '3.5rem',
    '--header-background-size': 'contain'
  }
};

const eurnet: Theme = {
  name: 'eurnet',
  logo: '../../../assets/img/marianne-blue.jpg',
  headerBackgroundImage: '../../../assets/img/bg-header_BDF_EN.png',
  headerInvertIconColor: false,
  version: 'MARIANNE v2',
  loginTitle: 'Distributed engine for Securities Settlement',
  headerTitle: 'Distributed Settlement Engine',
  footerLink: 'IBM',
  seeClient: false,
  seeCounterpart: true,
  menuMode: 'horizontal',
  properties: {
    '--color-theme-primary': '#135eb2', // $color-blue
    '--color-theme-primary-loader': '#137EE3', // $color-light-blue
    '--color-theme-primary-dark': '#003b7d', // $color-dark-blue
    '--color-theme-primary-very-dark': '#002A54', // $color-veary-dark-blue
    '--header-title-color': '#000000',
    '--header-text-color': '#000000',
    '--header-logo-height': '3.5rem',
    '--header-background-size': 'contain'
  }
};

const xxxnet: Theme = {
  name: 'xxxnet',
  logo: '../../../assets/img/marianne-green.jpg',
  headerBackgroundImage: '../../../assets/img/bg-header_BDF_EN.png',
  headerInvertIconColor: false,
  version: 'MARIANNE v2',
  loginTitle: 'Distributed engine for Securities Settlement',
  headerTitle: 'Distributed Settlement Engine',
  footerLink: 'IBM',
  seeClient: false,
  seeCounterpart: true,
  menuMode: 'horizontal',
  properties: {
    '--color-theme-primary': '#00643e', // $color-green
    '--color-theme-primary-loader': '#007d4d', // $color-light-green
    '--color-theme-primary-dark': '#004b2e', // $color-dark-green
    '--color-theme-primary-very-dark': '#004b2e', // $color-veary-dark-green
    '--header-title-color': '#000000',
    '--header-text-color': '#000000',
    '--header-logo-height': '3.5rem',
    '--header-background-size': 'contain'
  }
};

export const themes: { [key: string]: Theme } = {
  IBM: ibm,
  BDF: bdf,
  EURNET: eurnet,
  XXXNET: xxxnet
};
