// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

export class TrimTool {
  public static applyTrimOnObject<T>(object: T): T {
    for (const k of Object.keys(object)) {
      if (typeof object[k] === 'object' && object[k] !== null) {
        object[k] = this.applyTrimOnObject(object[k]);
      } else if (typeof object[k] === 'string') {
        object[k] = object[k].trim();
      }
    }
    return object;
  }
}
