import { CashRole, Role } from '@models/roles/role';
import { Tools } from '@app/services/common/tools';
import { ScopeType } from '@models/scope/scope.enum';

export class User {
  public isDCWOwner: boolean;
  public isDCWManager: boolean;
  public isDCWCustodian: boolean;
  public isCBDCSupervisor: boolean;
  public isCBDCIssuer: boolean;
  public isOperator: boolean;
  public isDCWUser: boolean;
  public isNationalCBDCSupervisor: boolean;
  public isExternalEntity: boolean = false;
  public isCreditProvider: boolean;

  public max4EyesRole: ScopeType | null;

  public constructor(
    public id: string,
    public username: string,
    public givenName: string,
    public familyName: string,
    public email: string,
    public company: string,
    public roles: string[],
    public permissions: string[],
    public entity: string,
    public blockAccessOrg: string,
    public appRoles: Role[] | string[],
    public externalEntityID?: string,
    public networkID?: string,
  ) {
    this.isDCWUser = appRoles.includes(CashRole.CASHWALLUSER);
    this.isDCWOwner = appRoles.includes(CashRole.CASHWALLOWN);
    this.isDCWManager = appRoles.includes(CashRole.CASHWALLMNG);
    this.isDCWCustodian = appRoles.includes(CashRole.CASHWALLCUSTODIAN);
    this.isCBDCSupervisor = appRoles.includes(CashRole.CASHSUPER);
    this.isCBDCIssuer = appRoles.includes(CashRole.CASHISSUER);
    this.isNationalCBDCSupervisor = appRoles.includes(CashRole.NATIONALCBDCSUPERVISOR);
    this.isOperator = appRoles.includes(CashRole.DL3SOPERATOR);
    this.isCreditProvider = appRoles.includes(CashRole.INTRADAYCREDITPROVIDER);
    this.isExternalEntity = !!externalEntityID || !!networkID;

    this.max4EyesRole = Tools.getMaxScopeCashFromRolesForFourEyes(this.appRoles);
  }

  public getContext(): string {
    if (this.company === this.blockAccessOrg || this.blockAccessOrg === '') {
      return ' @ ' + this.company;
    }
    return ' @ ' + this.company + ' @ ' + this.blockAccessOrg;
  }
}
