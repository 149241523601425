// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { ServiceError } from './service.error';

type ErrorName = 'InvalidLengthError' | 'CreationFailedError';

export const UserErrorList: Record<ErrorName, ServiceError> = {
  InvalidLengthError: {
    message: 'Username must be at least 3 characters long',
    type: 'ValidationError'
  },
  CreationFailedError: {
    message: 'Error adding user',
    type: 'UnknownError'
  }
};
