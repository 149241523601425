<mat-form-field appearance="fill">
  <input
    matInput
    [matDatepickerFilter]="props.filter"
    [matDatepicker]="validFromPicker"
    [formControl]="formControl"
    [name]="props.key"
    [placeholder]="props.placeholder" />
  <mat-datepicker-toggle matSuffix [for]="validFromPicker"></mat-datepicker-toggle>
  <mat-datepicker #validFromPicker></mat-datepicker>
</mat-form-field>
