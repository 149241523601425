<div class="formly-container box">
  <div class="formly-title">
    <span>{{props.label}}</span>
    <div class="reference-data">
      <formly-form [fields]="referenceDataFields" [model]="model"></formly-form>
    </div>
  </div>

  <div class="formly-body row">
    <ng-container #fieldComponent></ng-container>
  </div>
</div>
