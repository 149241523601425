// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Tools } from '../services/common/tools';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { AppConfig } from '@app/app.config';
import { IdentityProviders } from '@env/environment.model';

@Injectable({ providedIn: 'root' })
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private _configService: AppConfig
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const identityProvider = this._configService.getConfig('identityProvider');
    if (identityProvider?.name === IdentityProviders.KEYCLOAK) {
      return this.keycloakAuthorize(route, state);
    }
    return this.accessAuthorize(route);
  }

  public async keycloakAuthorize(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url
      });
    }

    const requiredRoles = route.data.roles;

    if (!Array.isArray(requiredRoles) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.roles.includes(role));
  }

  public async accessAuthorize(route: ActivatedRouteSnapshot): Promise<boolean> {
    if (!Tools.isSessionUp()) {
      return this.router.navigateByUrl('/login').then((): boolean => false);
    }

    return true;
  }
}
