import { Pipe, PipeTransform } from '@angular/core';
import { BusinessWindow, BusinessWindowScope } from "@models/business-date/business-date.model";
import { Observable, timer } from "rxjs";
import { map } from "rxjs/operators";
import { BusinessWindowHelper } from '@models/business-date/business-window.helper';

@Pipe({
  name: 'currentWindow'
})
export class CurrentWindowPipe implements PipeTransform {
  public static TIMEZONE: string = "Europe/Paris";

  public transform(businessWindows: BusinessWindow[], refreshEveryNSeconds: number = 0): Observable<BusinessWindowScope | ""> {
    return timer(0, refreshEveryNSeconds * 1000).pipe(map(() => {
        try {
          return BusinessWindowHelper.computeCurrentWindow(businessWindows).name;
        } catch (e) {
          return "";
        }
      }
    ));
  }
}
