export enum BusinessWindowScope {
  START_OF_DAY = "Start of Day",
  OPEN_FOR_ALL = "Open for All",
  END_OF_DAY = "End of Day",
  CLOSED = "Closed",
}

export interface BusinessWindow<T = string, U = BusinessWindowScope, V = string[]> {
  windowID: T;
  nextWindowID: T;
  name: U;
  startTime: T;
  authorizedRoles: V;
}

export const defaultBusinessWindows: BusinessWindow[] = [
  {
    windowID: "1",
    nextWindowID: "2",
    name: BusinessWindowScope.START_OF_DAY,
    startTime: "09:00",
    authorizedRoles: ["hello there", "youpeee"]
  },
  {
    windowID: "2",
    nextWindowID: "3",
    name: BusinessWindowScope.OPEN_FOR_ALL,
    startTime: "10:00",
    authorizedRoles: []
  },
  {
    windowID: "3",
    nextWindowID: "4",
    name: BusinessWindowScope.END_OF_DAY,
    startTime: "14:00",
    authorizedRoles: []
  },
  {
    windowID: "4",
    nextWindowID: "1",
    name: BusinessWindowScope.CLOSED,
    startTime: "15:30",
    authorizedRoles: []
  }
];
