// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseHttpRequestOptionsService } from '../common/base-http-request-options.service';
import { environment } from '@env/environment';
import { first, map } from 'rxjs/operators';
import { Routing } from '@app/core/enum/routing.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { TrimTool } from '../common/trim-tool/trim-tool';
import {
  BusinessWindow
} from "@models/business-date/business-date.model";

@Injectable()
export class ParametersService {
  private readonly _baseOctopusUrl: string = `${environment.octopusUrl}api/octopus`;

  public constructor(
    private httpClient: HttpClient,
    private baseHttpRequestOptionsService: BaseHttpRequestOptionsService
  ) {}

  public getBusinessDate(): Observable<string> {
    const businessDateUrl = `${this._baseOctopusUrl}${Routing.BusinessDate}`;
    const header = this.baseHttpRequestOptionsService.create();
    return this.httpClient.get<{ business_date: string }>(businessDateUrl, header).pipe(
      first(),
      map(
        (res: { business_date: string }) => {
          return res.business_date;
        },
        (error: HttpErrorResponse) => {
          throw error;
        }
      )
    );
  }

  public updateBusinessDate(newBusinessDate: { business_date: string }): Observable<any> {
    const businessDateUrl = `${this._baseOctopusUrl}${Routing.BusinessDate}`;
    const header = this.baseHttpRequestOptionsService.create();
    const trimmedBusinessDate: any = TrimTool.applyTrimOnObject<any>(newBusinessDate);
    return this.httpClient.put(businessDateUrl, trimmedBusinessDate, header).pipe(
      map(
        (res: any) => {
          return res.business_date;
        },
        (error: HttpErrorResponse) => {
          throw error;
        }
      )
    );
  }

  public getBusinessWindows(): Observable<BusinessWindow[]> {
    const businessDateUrl: string = `${this._baseOctopusUrl}${Routing.BusinessWindows}`;
    const header: { headers: HttpHeaders } = this.baseHttpRequestOptionsService.create();
    return this.httpClient.get<BusinessWindow[]>(businessDateUrl, header);
  }

  public updateBusinessWindows(businessWindows: BusinessWindow[]): Observable<BusinessWindow[]> {
    const businessWindowUrl: string = `${this._baseOctopusUrl}${Routing.BusinessWindows}`;
    const headers: { headers: HttpHeaders } = this.baseHttpRequestOptionsService.create();
    return this.httpClient.put<BusinessWindow[]>(businessWindowUrl, businessWindows, headers);
  }

  public getTimeoutRollback(): Observable<number> {
    const header = this.baseHttpRequestOptionsService.create();
    const url = `${this._baseOctopusUrl}${Routing.TimeoutRollbackGet}`;
    return this.httpClient.get<{ timeout_rollback: number }>(url, header).pipe(
      map((res: { timeout_rollback: number }) => {
        return res.timeout_rollback;
      })
    );
  }

  public recycle(): Observable<any> {
    const header = this.baseHttpRequestOptionsService.create();
    const url = `${this._baseOctopusUrl}${Routing.InstructionsRecycle}`;
    return this.httpClient.post(url, null, header);
  }

  public cbdcBDClosure(): Observable<any> {
    const header = this.baseHttpRequestOptionsService.create();
    const url = `${this._baseOctopusUrl}${Routing.CbdcBDClosure}`;
    return this.httpClient.get(url, header);
  }

  public getAssetType(): Observable<string> {
    const header = this.baseHttpRequestOptionsService.create();
    const url = `${this._baseOctopusUrl}${Routing.GrsAssetType}`;
    return this.httpClient.get<string>(url, header).pipe(
      map((res: any) => {
        return String(res.replaceAll('"', ''));
      })
    );
  }

  public processEvents() {
    let processEventsUrl: string;
    if (environment['caEventMode'] === 'caes') {
      processEventsUrl = `${this._baseOctopusUrl}${Routing.CaesProcessEvents}`;
    } else {
      processEventsUrl = `${this._baseOctopusUrl}${Routing.CalatheaProcessEvents}`;
    }
    const header = this.baseHttpRequestOptionsService.create();
    return this.httpClient.post(processEventsUrl, {}, header);
  }

  public getCurrentVersion(): Observable<string>{
    return this.httpClient.get('../assets/config/version.json').pipe(
      map((res: any) => {
        return JSON.parse(JSON.stringify(res));
      })
    )
  }
}
