// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Tools } from './tools';

@Injectable()
export class BaseHttpRequestOptionsService {
  public create(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + Tools.getSessionToken()
      })
    };
  }
  public createPost(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        responseType: 'text',
        Authorization: 'Bearer ' + Tools.getSessionToken()
      })
    };
  }
  public createUploadFileHeader(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + Tools.getSessionToken()
      })
    };
  }
}
