// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseHttpRequestOptionsService } from '../common/base-http-request-options.service';
import { environment } from '@env/environment';
import { ParticipantDto } from '@app/models/participants/participant.dto';
import { Routing } from '@app/core/enum/routing.enum';

@Injectable()
export class ParticipantsService {
  private readonly _baseOctopusUrl: string = `${environment.octopusUrl}api/octopus`;

  public constructor(
    private httpClient: HttpClient,
    private baseHttpRequestOptionsService: BaseHttpRequestOptionsService
  ) {}

  public getParticipants(networkId: string): Observable<ParticipantDto[]> {
    const listUrl = `${this._baseOctopusUrl}${Routing.Participants}/${networkId}/participants`;
    const header = this.baseHttpRequestOptionsService.create();
    return this.httpClient.get<Array<ParticipantDto>>(listUrl, header);
  }

  public getAllParticipants(): Observable<ParticipantDto[]> {
    const listUrl = `${this._baseOctopusUrl}${Routing.Participants}/participants`;
    const header = this.baseHttpRequestOptionsService.create();
    return this.httpClient.get<Array<ParticipantDto>>(listUrl, header);
  }
}
