// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Injectable } from '@angular/core';
import { LiquidityDto } from '../models/liquidity/liquidity-transfer/liquidity.dto';
import { LiquidityModel } from '../models/liquidity/liquidity-transfer/liquidity.model';
import { Mapper } from './mapper';

@Injectable()
export class CbdcMapper implements Mapper<LiquidityModel, LiquidityDto> {
  toEntity(dto: LiquidityDto): LiquidityModel {
    const cbdc = new LiquidityModel();
    cbdc.instructionLTID = dto.instructionLTID;
    cbdc.techCBDCOperationID = dto.techCBDCOperationID;
    cbdc.senderID = dto.senderID;
    cbdc.instructingPartyID = dto.instructingPartyID;
    cbdc.onBehalfOwner = dto.onBehalfOwner;
    cbdc.onBehalfUser = dto.onBehalfUser;
    cbdc.amountTransferred = dto.amountTransferred;
    cbdc.currency = dto.currency;
    cbdc.debitedCashWalletAlias = dto.debitedCashWalletAlias;
    cbdc.debitedCashWalletManagerID = dto.debitedCashWalletManagerID;
    cbdc.debitedNetworkID = dto.debitedNetworkID;
    cbdc.creditedCashWalletAlias = dto.creditedCashWalletAlias;
    cbdc.creditedCashWalletManagerID = dto.creditedCashWalletManagerID;
    cbdc.creditedNetworkID = dto.creditedNetworkID;
    cbdc.etatsUX = dto.etatsUX;
    cbdc.acceptedTimestamp = new Date(dto.timestamps?.ACCEPTED?.calendarDate);
    cbdc.creationDate = dto.timestamps?.ACCEPTED?.businessDate;
    cbdc.type = dto.type;
    cbdc.timestamps = dto.timestamps;
    cbdc.historicStatus = dto.historicStatus;
    cbdc.initiatorUserName = dto.initiatorUserName;
    cbdc.approverUserName = dto.approverUserName;
    cbdc.operationContext = dto.operationContext;
    cbdc.poaID = dto.poaID;

    cbdc.ISD = dto.ISD
    cbdc.supplementaryData = dto.supplementaryData

    cbdc.settledTimestamp = dto.settlementTime;
    cbdc.settledDate = dto.settlementDate;
    return cbdc;
  }

  toEntities(dtos: LiquidityDto[]): LiquidityModel[] {
    return dtos.map((dto) => this.toEntity(dto));
  }

  toDto(entity: LiquidityModel): LiquidityDto {
    throw new Error('Method not implemented.');
  }

  toDtos(entities: LiquidityModel[]): LiquidityDto[] {
    throw new Error('Method not implemented.');
  }
}
