// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { CashRole, Role, SecurityRole } from '@models/roles/role';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from '@models/access/user.model';
import { ScopeType } from '@models/scope/scope.enum';
import FontFaceObserver from 'fontfaceobserver';

export class Tools {
  public static readonly TOKEN_KEY = 'token';
  public static readonly MENU_COLLAPSED_KEY = 'menuCollapsed';
  public static readonly USER_KEY = 'currentUser';
  public static roles = { ...CashRole, ...SecurityRole };

  public static handleError(error: HttpErrorResponse, callback: (err) => Promise<any>): Promise<any> {
    console.error('An error occurred: ', error);
    return callback(error);
  }

  public static handleResult(res: HttpResponse<any>, callback: (res) => Promise<any>): Promise<any> {
    const token = res['token'];
    if (token) {
      localStorage.setItem('token', JSON.stringify(token));
    }
    return callback(res);
  }

  public static createAuthorizationHeader(): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Authorization', 'Bearer ' + Tools.getSessionToken());
    // headers.append('Content-Type', 'application/json');
    return headers;
  }

  public static setupSessionMaterial(user: User, token: string): void {
    localStorage.setItem(Tools.TOKEN_KEY, JSON.stringify(token));
    localStorage.setItem(Tools.USER_KEY, JSON.stringify(user));
  }

  public static isSessionUp(): boolean {
    return !(!localStorage.getItem(Tools.USER_KEY) || !localStorage.getItem(Tools.TOKEN_KEY));
  }

  public static getSessionUser(): User | null {
    const userStringified: string | null = localStorage.getItem(this.USER_KEY);
    if (userStringified == null) {
      return null;
    }
    return Object.setPrototypeOf(JSON.parse(userStringified), User.prototype);
  }

  public static getUserEntity(): string {
    const user: User | null = this.getSessionUser();
    return user?.entity ?? '';
  }

  public static getSessionToken(): string {
    return JSON.parse(localStorage.getItem(Tools.TOKEN_KEY));
  }

  // clear token and remove user from local storage to log user out
  public static cleanSessionMaterial(): void {
    localStorage.removeItem(Tools.USER_KEY);
    localStorage.removeItem(Tools.TOKEN_KEY);
    localStorage.clear();
  }

  public static isSessionExpired(): boolean {
    const token = JSON.parse(localStorage.getItem(Tools.TOKEN_KEY));
    if (!token) {
      return true;
    }
    const currentTime: number = new Date().getTime() / 1000;
    const jwt = Tools.parseJwt(token);
    return currentTime > jwt.exp;
  }

  public static parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  public static getRoles(): Array<string> {
    const user: User | null = Tools.getSessionUser();
    return user?.roles || [];
  }

  public static getAppRoles(): Array<string> {
    const user: User | null = Tools.getSessionUser();
    return user?.appRoles || [];
  }

  public static hasAppRole(...needsRoles: string[]): boolean {
    const roles: string[] = Tools.getAppRoles();
    for (const role of roles) {
      for (const needsRole of needsRoles) {
        if (role === needsRole) {
          return true;
        }
      }
    }
    return false;
  }

  public static hasSpecificRoles(...needsRoles: string[]): boolean {
    const roles: string[] = Tools.getAppRoles();

    if (roles.length !== needsRoles.length) {
      return false;
    }

    for (const needsRole of needsRoles) {
      if (!roles.includes(needsRole)) {
        return false;
      }
    }

    return true;
  }


  public static getMaxRoleSec(): Role | null {
    const roles: string[] = Tools.getAppRoles();
    if (roles.includes(SecurityRole.SECSUPER)) {
      return SecurityRole.SECSUPER;
    } else if (roles.includes(SecurityRole.SECWALLMNG)) {
      return SecurityRole.SECWALLMNG;
    } else if (roles.includes(SecurityRole.SECWALLOWN)) {
      return SecurityRole.SECWALLOWN;
    }
  }

  public static getMaxRoleCash(): Role | null {
    const roles: string[] = Tools.getAppRoles();
    if (roles.includes(CashRole.CASHSUPER)) {
      return CashRole.CASHSUPER;
    } else if (roles.includes(CashRole.CASHWALLMNG)) {
      return CashRole.CASHWALLMNG;
    } else if (roles.includes(CashRole.CASHWALLOWN)) {
      return CashRole.CASHWALLOWN;
    } else if (roles.includes(CashRole.CASHWALLUSER)) {
      return CashRole.CASHWALLUSER;
    }
  }

  public static getMaxScopeCashFromRoles(): ScopeType | null {
    const roles = JSON.parse(localStorage.getItem('currentUser')).appRoles as Array<string>;
    if (roles.includes(CashRole.CASHSUPER) || roles.includes(CashRole.DL3SOPERATOR)) {
      return ScopeType.SUPERVISED;
    } else if (roles.includes(CashRole.NATIONALCBDCSUPERVISOR) && roles.includes(CashRole.CASHWALLMNG)) {
      return ScopeType.MANAGED_NCB_SUPERVISED;
    } else if (roles.includes(CashRole.NATIONALCBDCSUPERVISOR)) {
      return ScopeType.NCB_SUPERVISED;
    } else if (roles.includes(CashRole.CASHWALLMNG)) {
      return ScopeType.MANAGED;
    } else if (roles.includes(CashRole.CASHWALLCUSTODIAN)) {
      return ScopeType.MANAGED_CUSTODY;
    } else if (roles.includes(CashRole.CASHWALLOWN)) {
      return ScopeType.OWNED;
    } else if (roles.includes(CashRole.CASHWALLUSER)) {
      return ScopeType.USED;
    }
    return null;
  }

  public static getMaxScopeCashFromRolesForFourEyes(roles: Role[] | string[]): ScopeType | null {
    if (roles.includes(CashRole.CASHSUPER)) {
      return ScopeType.SUPERVISED;
    } else if (roles.includes(CashRole.CASHISSUER)) {
      return ScopeType.ISSUED;
    } else if (roles.includes(CashRole.CASHWALLMNG)) {
      return ScopeType.MANAGED;
    } else if (roles.includes(CashRole.CASHWALLCUSTODIAN)) {
      return ScopeType.MANAGED_CUSTODY;
    } else if (roles.includes(CashRole.CASHWALLOWN)) {
      return ScopeType.OWNED;
    } else if (roles.includes(CashRole.CASHWALLUSER)) {
      return ScopeType.USED;
    }
    return null;
  }

  public static getMaxScopeCashFromRolesForFourEyesExcept(roles: Role[] | string[], except: ScopeType[]): ScopeType | null {
    const possibleScopes: ScopeType[] = [];
    if (roles.includes(CashRole.CASHSUPER)) {
      possibleScopes.push(ScopeType.SUPERVISED);
    }
    if (roles.includes(CashRole.CASHISSUER)) {
      possibleScopes.push(ScopeType.ISSUED);
    }
    if (roles.includes(CashRole.CASHWALLMNG)) {
      possibleScopes.push(ScopeType.MANAGED);
    }
    if (roles.includes(CashRole.CASHWALLCUSTODIAN)) {
      possibleScopes.push(ScopeType.MANAGED_CUSTODY);
    }
    if (roles.includes(CashRole.CASHWALLOWN)) {
      possibleScopes.push(ScopeType.OWNED);
    }
    if (roles.includes(CashRole.CASHWALLUSER)) {
      possibleScopes.push(ScopeType.USED);
    }
    const finalScopes: ScopeType[] = possibleScopes.filter((possibleScope: ScopeType) => !except.includes(possibleScope));
    return (finalScopes.length > 0) ? finalScopes[0] : null;
  }

  public static calculateOrder(actualSort: string, newSort: string, order: string): 'asc' | 'desc' {
    if (newSort === actualSort && order === 'asc') {
      return 'desc';
    }
    return 'asc';
  }

  public static sortElementsInArray<T>(array: T[], column: string, mainColumn: string, order: 'asc' | 'desc') {
    return _.orderBy<T>(array, [column, mainColumn], order);
  }

  public static setMenuCollapsed(menuCollapsed: boolean): void {
    localStorage.setItem(Tools.MENU_COLLAPSED_KEY, JSON.stringify(menuCollapsed));
  }

  public static getMenuCollapsed(): boolean {
    return localStorage.getItem(Tools.MENU_COLLAPSED_KEY) === 'true';
  }

  public static displayInactiveObject(
    validFromStr: string,
    validToStr: string,
    businessDate: Date,
    isHistoricVersion: boolean
  ): boolean {
    if (isHistoricVersion) {
      return true;
    }
    const validFrom = new Date(validFromStr);
    if (businessDate < validFrom) {
      return true;
    }
    if (validToStr !== '') {
      const validTo = new Date(validToStr);
      if (businessDate > validTo) {
        return true;
      }
    }

    return false;
  }

  public static displayActiveObject(validFromStr: string, validToStr: string, businessDate: Date): boolean {
    const validFrom = new Date(validFromStr);
    if (businessDate < validFrom) {
      return false;
    }
    if (validToStr !== '') {
      const validTo = new Date(validToStr);
      if (businessDate > validTo) {
        return false;
      }
    }

    return true;
  }

  public static displayActiveAndFutureObject(validFromStr: string, validToStr: string, businessDate: Date): boolean {
    const validFrom = new Date(validFromStr);
    if (validToStr !== '') {
      const validTo = new Date(validToStr);

      if (validFrom > businessDate && validTo > businessDate) {
        return true;
      }

      if (validFrom < businessDate && validTo > businessDate) {
        return true;
      }

      if (businessDate > validTo) {
        return false;
      }
    }

    return true;
  }

  public static displayInActiveAndFutureObject(validFromStr: string, validToStr: string, businessDate: Date): boolean {
    const validFrom = new Date(validFromStr);
    if (validToStr !== '') {
      const validTo = new Date(validToStr);

      if (validFrom > businessDate && validTo > businessDate) { // future
        return true;
      }

      if (validFrom < businessDate && validTo < businessDate) { // inactive
        return true;
      }

      if (validFrom < businessDate && validTo > businessDate) { // active
        return false;
      }

      if (businessDate > validTo) {
        return false;
      }
    }

    return businessDate < validFrom;
  }

  public static displayFutureObject(validFromStr: string, validToStr: string, businessDate: Date): boolean {
    const validFrom = new Date(validFromStr);
    if (validToStr !== '') {
      const validTo = new Date(validToStr);

      if (validFrom > businessDate && validTo > businessDate) {
        return true;
      }
    }

    return validFrom > businessDate;
  }

  public static waitFontsLoad(): Promise<boolean> {
    let regularFont = new FontFaceObserver('open-sans-regular');
    let boldFont = new FontFaceObserver('open-sans-bold');
    let lightFont = new FontFaceObserver('open-sans-light');
    let semiBoldFont = new FontFaceObserver('open-sans-semi-bold');

    return Promise.all([regularFont.load(), boldFont.load(), lightFont.load(), semiBoldFont.load()])
      .then(() => {
        return true;
      })
      .catch((err) => {
        return true;
      });
  }

  public static isManaged(role: ScopeType): boolean {
    return role === ScopeType.MANAGED;
  }

  public static isManagedOwnedSupervised(scope: ScopeType): boolean {
    return [
      ScopeType.MANAGED,
      ScopeType.MANAGED_CUSTODY,
      ScopeType.OWNED,
      ScopeType.OWNED_CUSTODY,
      ScopeType.SUPERVISED,
      ScopeType.MANAGED_NCB_SUPERVISED,
      ScopeType.NCB_SUPERVISED
    ].includes(scope);
  }

}
