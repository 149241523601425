// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { NgModule } from '@angular/core';

import { LocalFilterPipe } from './local-filter.pipe';

@NgModule({
  imports: [],
  declarations: [LocalFilterPipe],
  exports: [LocalFilterPipe]
})
export class LocalFilterModule {}
