import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentWindowPipe } from './pipes/current-window.pipe';
import { SectionWrapperComponent } from './formly/section-wrapper/section-wrapper.component';
import { FormlyModule } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { FieldWrapperComponent } from './formly/field-wrapper/field-wrapper.component';
import { FormlyFieldInput } from '@ngx-formly/bootstrap/input';
import { FormlyFieldSelect } from '@ngx-formly/bootstrap/select';
import { FormlyFieldRadio } from '@ngx-formly/bootstrap/radio';
import { FormlyFieldDateComponent } from '@app/components/shared/formly/date-type/date-type.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RefDataWrapperComponent } from '@app/components/shared/formly/ref-data-wrapper/ref-data-wrapper.component';
import { RepeatSectionTypeComponent } from '@app/components/shared/formly/repeat-section-type/repeat-section-type.component';
import { SilentRepeatTypeComponent } from '@app/components/shared/formly/silent-repeat-type/silent-repeat-type.component';

@NgModule({
  declarations: [
    CurrentWindowPipe,
    SectionWrapperComponent,
    RepeatSectionTypeComponent,
    RefDataWrapperComponent,
    FieldWrapperComponent,
    FormlyFieldDateComponent,
    SilentRepeatTypeComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'input',
          component: FormlyFieldInput,
          wrappers: ['field']
        },
        {
          name: 'radio',
          component: FormlyFieldRadio,
          wrappers: ['field']
        },
        {
          name: 'date',
          component: FormlyFieldDateComponent,
          wrappers: ['field']
        },
        {
          name: 'select',
          component: FormlyFieldSelect,
          wrappers: ['field']
        },
        {
          name: 'silent-repeat',
          component: SilentRepeatTypeComponent
        },
        {
          name: 'repeat',
          component: RepeatSectionTypeComponent
        }
      ],
      wrappers: [
        { name: 'section', component: SectionWrapperComponent },
        { name: 'field', component: FieldWrapperComponent },
        { name: 'ref-data-section', component: RefDataWrapperComponent }
      ],
      validationMessages: [
        { name: 'required', message: 'This field is required' },
      ],
    }),
    FormsModule,
    MatRadioModule,
    MatInputModule
  ],
  exports: [CurrentWindowPipe],
})
export class SharedModule {}
