<ng-template #labelTemplate>
  <label *ngIf="props.label && props.hideLabel !== true" [attr.for]="id !== '' ? id : null" class="form-label">
    {{ props.label }}
    <span *ngIf="props.required && props.hideRequiredMarker !== true" [class.has-error]="showError && field.formControl.hasError('required')" aria-hidden="true">*</span>
  </label>
</ng-template>

<div class="body" [class.form-floating]="props.labelPosition === 'floating'" [class.has-error]="showError">
  <ng-container *ngIf="props.labelPosition !== 'floating'">
    <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
  </ng-container>

  <ng-template #fieldComponent></ng-template>

  <ng-container *ngIf="props.labelPosition === 'floating'">
    <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
  </ng-container>

  <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
    <formly-validation-message
      id="{{ id }}-formly-validation-error"
      [field]="field"
      role="alert"
    ></formly-validation-message>
  </div>

  <small *ngIf="props.description" class="form-text text-muted">{{ props.description }}</small>
</div>
