// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Injectable } from '@angular/core';
import { MenuMode, Theme, themes } from '@app/services/theme/theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private active: Theme = themes['BDF'];
  private displayOnHeader = false;
  private icons: { [key: string]: string } = {
    BDF: 'assets/img/logo_BDF_bleu_RVB.png',
    IBM: 'assets/img/marianne-black.png',
    EURNET: 'assets/img/marianne-blue.jpg',
    XXXNET: 'assets/img/marianne-green.jpg'
  };

  public setFavIcon(theme: string): string {
    return this.icons[theme] || this.icons['BDF'];
  }

  public setLogo(): string {
    return this.active.logo;
  }

  public setCounterpart(): boolean {
    return this.active.seeCounterpart;
  }

  public setVersion(theme: string): AppVersion {
    if (theme === 'BDF') {
      this.displayOnHeader = true;
    }
    return { version: this.active.version, displayOnHeader: this.displayOnHeader };
  }

  public getFooterLink(): string {
    return this.active.footerLink;
  }

  public setHeaderTitle(): string {
    return this.active.headerTitle;
  }

  public setLoginTitle(): string {
    return this.active.loginTitle;
  }

  public setTheme(theme: string) {
    this.setActiveTheme(themes[theme]);
  }

  public setActiveTheme(theme: Theme): void {
    this.active = theme;
    Object.keys(this.active.properties).forEach((property) => {
      document.documentElement.style.setProperty(property, this.active.properties[property]);
    });
  }

  public displayClient(): boolean {
    return this.active.seeClient;
  }

  public getHeaderBackgroundImage(): string {
    return this.active.headerBackgroundImage;
  }

  public getHeaderBackgroundColor(): string {
    return this.active.headerBackgroundColor;
  }

  public getHeaderInvertIconColor(): boolean {
    return this.active.headerInvertIconColor;
  }

  public getMenuMode(): MenuMode {
    return this.active.menuMode;
  }
}

export interface AppVersion {
  version: string;
  displayOnHeader: boolean;
}
