// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable()
export class FormValidationService {
  public isFormValid(formGroup: FormGroup): boolean {
    this.validateForm(formGroup);

    return formGroup.valid;
  }

  private validateForm(formGroup: FormGroup): void {
    for (const control of Object.keys(formGroup.controls)) {
      this.validateFormControl(control, formGroup);
    }
  }

  private validateFormControl(control: string, formGroup: FormGroup): void {
    formGroup.controls[control].markAsDirty();
    formGroup.controls[control].markAsTouched();
    formGroup.controls[control].updateValueAndValidity();
  }
}
