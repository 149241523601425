import { Component } from '@angular/core';
import { FieldArrayType } from "@ngx-formly/core";

@Component({
  selector: 'formly-silent-repeat',
  templateUrl: './silent-repeat-type.component.html',
  styleUrls: ['./silent-repeat-type.component.scss']
})
export class SilentRepeatTypeComponent extends FieldArrayType {
}
